<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Connexion </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field prepend-icon="mdi-email" name="email" label="Email"
                  placeholder="nom.prenom@avenir-assurances.com" type="text" v-model="email"></v-text-field>
                <v-text-field id="password" prepend-icon="mdi-lock" name="password" label="Password" type="password"
                  v-model="password"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!validate()" color="primary" @click="connect" :loading="$store.state.fetching">Se
                connecter</v-btn>
              <v-btn icon @click="clearFields"><v-icon dark> mdi-autorenew </v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    validate() {
      return this.password.length >= 4 && this.email.length >= 4;
    },
    async connect() {
      const email = this.email;
      const password = this.password;
      await this.login({ email, password });
      this.$router.push("/");
    },
    clearFields() {
      this.email = "";
      this.password = "";
      this.$store.state.fetching = false;
    },
  },
};
</script>
