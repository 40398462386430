<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="error" text v-bind="attrs" v-on="on"> Supprimer </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 error">
          Supprimer l'utilisateur
        </v-card-title>

        <v-card-text class="mt-4">
          Vous allez <strong>supprimer un utilisateur</strong> , cette action
          est <strong>irréversible</strong> . L'ensemble des informations de
          l'utilisateur seront supprimées et ne pourront
          <strong>pas être restaurées</strong>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="dialog = false"> Annuler </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteCustomer"
            :loading="$store.state.fetching"
          >
            Je supprime
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async deleteCustomer() {
      try {
        this.$store.commit("setFetching", true);
        const id = this.customerId;
        if (!id) return;
        const { data } = await this.$http.delete(`/customer/${id}`);
        if (data.success) {
          this.$router.push("/");
        }
      } catch (error) {
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setFetching", false);
      }
    },
  },
};
</script>
