import Vue from "vue";
import Vuex from "vuex";
import api from "../utils/api";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    fetching: false,
    errorSnackbar: { display: false, message: "" },
    messagePosting: false,
  },
  getters: {
    isAuthenticated: (state) => !!state.user && !!localStorage.getItem("token"),
    user: (state) => state.user,
    userFirstname: (state) => {
      const firstname = state.user.split(".")[0];
      return firstname.charAt(0).toUpperCase() + firstname.slice(1);
    },
    userInitials: (state) => {
      const firstname = state.user.split(".")[0].charAt(0).toLocaleUpperCase();
      const lastname = state.user.split(".")[1].charAt(0).toLocaleUpperCase();
      return firstname + lastname;
    },
  },
  mutations: {
    setFetching(state, payload) {
      state.fetching = payload;
    },
    setMessagePosting(state, payload) {
      state.messagePosting = payload;
    },
    setErrorSnackbar(state, payload) {
      const { display, message } = payload;
      state.errorSnackbar = { display, message };
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        commit("setFetching", true);
        const { data } = await api.post("/auth/login", payload);
        const { email, token } = data;
        localStorage.setItem("token", token);
        commit("setUser", email);
      } catch (error) {
        commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        commit("setFetching", false);
      }
    },
  },
  modules: {},
});
