import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./utils/api";

Vue.config.productionTip = false;

Vue.prototype.$http = api;

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers.common["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("token");
          router.replace({
            path: "/connexion",
          });
          break;
        case 403:
          localStorage.removeItem("token");
          router.replace({
            path: "/connexion",
          });
          break;
        case 404:
          // TODO
          break;
        case 502:
          router.replace({
            path: "/connexion",
          });
      }
      return Promise.reject(error.response);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
