<template>
  <v-container>
    <v-row justify="end">
      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="type === 'creation'"
            rounded
            class="my-4"
            color="success"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
            Ajouter un client
          </v-btn>
          <v-btn
            v-else
            text
            class="my-4"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Modifier
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nom *"
                    v-model="lastName"
                    required
                    :rules="[(v) => !!v || 'Un nom doit être renseigné']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Prénom *"
                    v-model="firstName"
                    required
                    :rules="[(v) => !!v || 'Un prénom doit être renseigné']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Email *"
                    v-model="email"
                    required
                    :rules="[(v) => !!v || 'Un email doit être renseigné']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Numéro de téléphone *"
                    v-model="phoneNumber"
                    required
                    :rules="[(v) => !!v || 'Un email doit être renseigné']"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Adresse *"
                    v-model="streetName"
                    placeholder="23 Rue Dagobert"
                    required
                    :rules="[(v) => !!v || 'Un nom de rue doit être renseigné']"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Code postal *"
                    v-model="zipCode"
                    placeholder="92110"
                    required
                    :counter="5"
                    :rules="[
                      (v) => !!v || 'Un code postal doit être renseigné',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Ville *"
                    :items="cities"
                    v-model="city"
                    placeholder="Clichy"
                    required
                    :rules="[(v) => !!v || 'Une ville doit être renseignée']"
                    @click="findCitiesWithPostalCode"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-combobox
                    :items="[
                      'Prospect',
                      'Client',
                      'En attente des pièces',
                      'Tarification',
                      'En attente de réglement',
                      'Relance réglement',
                      'En règle',
                    ]"
                    label="Statut"
                    v-model="status"
                  ></v-combobox>
                </v-col>
                <v-col xs="12" sm="6">
                  <v-combobox
                    v-model="contracts"
                    label="Codes contrat"
                    multiple
                    chips
                    clearable
                    color="primary"
                    hint="Pensez à appuyer sur la touche Entrée après chaque n° de contrat saisi"
                    :persistent-hint="true"
                  ></v-combobox>
                </v-col>
                <v-col xs="12" sm="3">
                  <v-text-field
                    label="Nom de la compagnie"
                    v-model="companyName"
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="3">
                  <v-text-field
                    label="Cotisation mensuelle"
                    v-model="monthlyContribution"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>* Champs obligatoires</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="type === 'creation'"
              color="primary"
              text
              @click="createCustomer"
              :disabled="!formValid"
              :loading="$store.state.fetching"
            >
              Créer
            </v-btn>
            <v-btn
              v-else
              color="primary"
              text
              @click="updateCustomer"
              :loading="$store.state.fetching"
            >
              Modifier
            </v-btn>
            <v-btn color="error" text @click="resetForm"> Annuler </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import findCity from "@/utils/postalCode";
export default {
  props: {
    type: {
      type: String,
      default: "creation",
    },
    customer: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.resetForm();
  },
  data: () => ({
    valid: false,
    dialog: false,
    lastName: null,
    firstName: null,
    email: null,
    phoneNumber: null,
    streetName: null,
    zipCode: null,
    city: null,
    cities: [],
    status: "Prospect",
    contracts: [],
    companyName: null,
    monthlyContribution: null,
  }),
  methods: {
    resetForm() {
      if (this.type !== "creation" && this.customer !== null) {
        this.lastName = this.customer.lastName;
        this.firstName = this.customer.firstName;
        this.email = this.customer.email;
        this.streetName = this.customer.streetName;
        this.zipCode = this.customer.zipCode;
        this.status = this.customer.status;
        if (this.customer.contracts)
          this.contracts = this.customer.contracts.split(", ");
        if (this.customer.companyName)
          this.companyName = this.customer.companyName;
        if (this.customer.phoneNumber)
          this.phoneNumber = this.customer.phoneNumber;
        if (this.customer.monthlyContribution)
          this.monthlyContribution = this.customer.monthlyContribution;
      } else {
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.phoneNumber = null;
        this.streetName = null;
        this.zipCode = null;
        this.city = null;
        this.status = null;
        this.companyName = null;
        this.contracts = [];
        this.monthlyContribution = null;
      }
      this.dialog = false;
    },
    async createCustomer() {
      const contracts = this.contracts.join(", ");
      const {
        lastName,
        firstName,
        email,
        phoneNumber,
        streetName,
        zipCode,
        city,
        status,
        companyName,
        monthlyContribution,
      } = this;

      const payload = {
        lastName,
        firstName,
        email,
        phoneNumber,
        streetName,
        zipCode,
        city,
        status,
        contracts,
        companyName,
        monthlyContribution,
      };

      try {
        this.$store.commit("setFetching", true);

        const { data } = await this.$http.post("/customer", payload);
        if (data.success) {
          this.resetForm();
          this.$root.$emit("newCustomer");
        }
      } catch (error) {
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setFetching", false);
      }
    },
    async updateCustomer() {
      const contracts = this.contracts.join(", ");
      const {
        lastName,
        firstName,
        email,
        phoneNumber,
        streetName,
        zipCode,
        city,
        status,
        companyName,
        monthlyContribution,
      } = this;
      const { id } = this.customer;
      const payload = {
        id,
        lastName,
        firstName,
        email,
        phoneNumber,
        streetName,
        zipCode,
        city,
        status,
        contracts,
        companyName,
        monthlyContribution,
      };

      try {
        this.$store.commit("setFetching", true);

        const { data } = await this.$http.put(`/customer`, payload);
        if (data.success) {
          this.resetForm();
          this.$root.$emit("customerUpdated", data.customer);
        }
      } catch (error) {
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setFetching", false);
      }
    },
    findCitiesWithPostalCode() {
      this.cities = findCity(this.zipCode);
    },
  },
  computed: {
    formValid() {
      return (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.phoneNumber &&
        this.streetName &&
        this.zipCode &&
        this.city
      );
    },
  },
};
</script>
