<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-template-key -->
<template>
  <v-list three-line>
    <v-list-item v-for="item in notes" :key="item.id">
      <v-list-item-avatar>
        <v-avatar color="brown" size="48">
          <span class="white--text text-h6">{{ initials(item.User) }}</span>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-subtitle>
          <strong>{{ name(item.User) }}</strong> -
          {{ new Date(item.createdAt).toLocaleString("fr-FR") }}
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ item.content }}
          <v-btn class="ml-2" color="error" icon @click="deleteNote(item.id)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      notes: [],
      author: null,
    };
  },
  async created() {
    await this.fetchNotes();
  },
  async mounted() {
    this.$root.$on("newNote", async (payload) => {
      this.notes.unshift(payload);
    });
  },
  methods: {
    async fetchNotes() {
      const id = this.$route.params.id;
      try {
        this.$store.commit("setMessagePosting", true);
        const { data } = await this.$http.get(`/note/customer/${id}`);
        data.notes.forEach((note) => {
          if (note.User.email === this.$store.state.user) note.deletable = true;
        });
        this.notes = data.notes.sort((a, b) => a.createdAt < b.createdAt);
      } catch (error) {
        console.error(error);
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setMessagePosting", false);
      }
    },
    async deleteNote(id) {
      try {
        const { data } = await this.$http.delete(`note/${id}`);
        if (data.success) {
          this.notes = [];
          await this.fetchNotes();
        }
      } catch (error) {
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      }
    },
    initials(author) {
      const { email } = author;
      const [firstname, lastname] = email.split(".");
      return (
        firstname.charAt(0).toLocaleUpperCase() +
        lastname.charAt(0).toLocaleUpperCase()
      );
    },
    name(author) {
      const { email } = author;
      const [firstname, lastname] = email.split("@")[0].split(".");
      return (
        firstname.charAt(0).toLocaleUpperCase() +
        firstname.slice(1) +
        " " +
        lastname.charAt(0).toLocaleUpperCase() +
        lastname.slice(1)
      );
    },
  },
};
</script>
