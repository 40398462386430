<template>
  <div v-if="!$store.state.fetching">
    <v-textarea
      name="note"
      label="Rédiger une note"
      filled
      auto-grow
      v-model="note"
      hint="Il est legalement interdit d'inscrire des propos injurieux, raciaux ou religieux dans un dossier client"
    ></v-textarea>
    <v-btn
      color="primary"
      :disabled="!note || note.length < 4 ? true : false"
      :loading="$store.state.messagePosting"
      @click="sendNote"
      >Envoyer</v-btn
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      note: null,
    };
  },
  methods: {
    async sendNote() {
      try {
        this.$store.commit("setMessagePosting", true);
        const customerId = this.$route.params.id;
        const payload = { content: this.note, customerId };
        const { data } = await this.$http.post(`/note`, payload);
        if (data.success) {
          const { note, User } = data;
          note.User = User;
          this.$root.$emit("newNote", note);
          this.note = null;
        }
      } catch (error) {
        console.warn(error);
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setMessagePosting", false);
      }
    },
  },
};
</script>
