<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <add-client-dialog />
        <data-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "./Home/DataTable.vue";
import AddClientDialog from "./Home/AddClientDialog.vue";
export default {
  components: {
    DataTable,
    AddClientDialog,
  },
  data: () => ({}),
};
</script>
