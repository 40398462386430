<template>
  <div>
    <v-data-table :headers="headers" :items="customers" item-key="id" class="elevation-1" :search="search"
      :custom-filter="filterOnlyCapsText" sort-by="updatedAt" :sortDesc="true">
      <template v-slot:top>
        <v-text-field v-model="search" label="Rechercher un client" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status === 'En règle' || item.status === 'Client'
            ? 'success'
            : 'primary'
          ">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.address="{ item }">
        {{ `${item.streetName}, ${item.city} (${item.zipCode}) ` }}
      </template>
      <template v-slot:item.link="{ item }">
        <v-btn icon color="primary" :href="'#/client/' + item.id">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  async created() {
    await this.fetchCustomers();
    this.$root.$on("newCustomer", async () => {
      await this.fetchCustomers();
    });
  },
  data() {
    return {
      search: "",
      customers: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Nom",
          align: "start",
          value: "lastName",
        },
        {
          text: "Prénom",
          align: "start",
          value: "firstName",
        },
        {
          text: "Statut",
          align: "start",
          value: "status",
        },
        { text: "Email", align: "start", value: "email" },
        { text: "Adresse", align: "start", value: "address" },
        { text: "Contrats", align: "center", value: "contracts" },
        { text: "Profil", align: "center", value: "link", sortable: false },
      ];
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    async fetchCustomers() {
      try {
        const { data } = await this.$http.get("/customer");
        this.customers = data.customers;
      } catch (error) {
        console.error(error);
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      }
    },
  },
};
</script>
