<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" sm="6" md="4">
        <customer-info :customerId="id" />
      </v-col>
      <!--       <v-col>
        <v-container fluid>
          <v-row>
            <v-col>
              <customer-file-upload />
            </v-col>
          </v-row>
          <v-row>
            <customer-file-viewer />
          </v-row>
        </v-container>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col xs="12" sm="12">
        <customer-notes-display />
      </v-col>
      <v-col xs="12" sm="12">
        <customer-notes-type-zone />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "../components/Customer/CustomerInfo.vue";
/* import CustomerFileUpload from "../components/Customer/CustomerFileUpload.vue";
import CustomerFileViewer from "../components/Customer/CustomerFileViewer.vue"; */
import CustomerNotesDisplay from "../components/Customer/CustomerNotesDisplay.vue";
import CustomerNotesTypeZone from "../components/Customer/CustomerNotesTypeZone.vue";
export default {
  components: {
    CustomerInfo,
    /* CustomerFileUpload,
    CustomerFileViewer, */
    CustomerNotesDisplay,
    CustomerNotesTypeZone,
  },
  data() {
    return {
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
};
</script>
