<!-- eslint-disable prettier/prettier -->
<template>
  <v-card v-if="customer" :loading="$store.state.fetching" class="mx-auto mt-2">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title
      >{{ customer.lastName.toLocaleUpperCase() }}
      {{ customer.firstName }}
    </v-card-title>
    <v-card-text>
      <v-row align-start class="mx-0"
        ><a
          v-if="customer.phoneNumber"
          :href="`tel:+33${customer.phoneNumber.slice(1)}`"
          >{{ customer.phoneNumber }}</a
        >
      </v-row>
      <v-row align="start" class="mx-0">
        <a :href="'mailto:' + customer.email">{{ customer.email }}</a>
      </v-row>
      <v-row align="start" class="mx-0">
        <div>{{ address }}</div>
      </v-row>

      <div class="mt-10 text-subtitle-1">
        <v-chip
          :color="
            customer.status === 'En règle' || customer.status === 'Client'
              ? 'success'
              : 'primary'
          "
        >
          {{ customer.status }}
        </v-chip>
        •
        {{ customer.contracts ? customer.contracts : "Pas de contrat" }}

        <strong>{{
          customer.companyName ? `• ${customer.companyName}` : ""
        }}</strong>
      </div>
      <div v-if="customer.monthlyContribution" class="my-4 text-subtitle-1">
        <v-chip color="success">
          Cotisation mensuelle : {{ customer.monthlyContribution }}€
        </v-chip>
      </div>
      <v-row align="start" class="mx-0 mt-2">
        <div>Client depuis le {{ clientSince }}{{ lastUpdate }}</div>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <add-client-dialog type="update" :customer="customer" />
      <delete-dialog :customerId="customer.id" />
    </v-card-actions>
  </v-card>
</template>

<script>
import AddClientDialog from "../Home/AddClientDialog.vue";
import DeleteDialog from "../Dialog/DeleteDialog.vue";

export default {
  components: {
    AddClientDialog,
    DeleteDialog,
  },
  data: () => ({
    customerId: null,
    customer: null,
  }),
  async created() {
    if (!this.customerId) {
      this.customerId = this.$route.params.id;
    }
    await this.fetchCustomer();
  },
  mounted() {
    this.$root.$on("customerUpdated", async () => {
      await this.fetchCustomer();
    });
  },
  methods: {
    async fetchCustomer() {
      const id = this.customerId;
      try {
        this.$store.commit("setFetching", true);
        const { data } = await this.$http.get(`/customer/${id}`);
        this.customer = data.customer;
      } catch (error) {
        this.$store.commit("setErrorSnackbar", {
          display: true,
          message: error.data.message,
        });
      } finally {
        this.$store.commit("setFetching", false);
      }
    },
  },
  computed: {
    address() {
      if (!this.customer) return;
      return `${this.customer.streetName}, ${this.customer.city} (${this.customer.zipCode}) `;
    },
    clientSince() {
      if (!this.customer) return;
      return new Date(this.customer.createdAt).toLocaleString("fr-FR", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    lastUpdate() {
      if (!this.customer) return;
      if (this.customer.createdAt === this.customer.updatedAt) return;
      return `, dernière modification le ${new Date(
        this.customer.updatedAt
      ).toLocaleString("fr-FR", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`;
    },
  },
};
</script>
